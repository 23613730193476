import React, { useState, useEffect, useCallback } from 'react';
import Grid from './grid';
import GridDimensionSelector from './grid-dim-selector';
import ColorSelector from './color-selector';
import './ui.css';

const colors = [
  { name: 0, hex: '#000000' },    // Black
  { name: 1, hex: '#1E93FF' },    // Blue
  { name: 2, hex: '#F93C31' },    // Red
  { name: 3, hex: '#4FCC30' },    // Green
  { name: 4, hex: '#FFDC00' },    // Yellow
  { name: 5, hex: '#999999' },    // Grey
  { name: 6, hex: '#E53AA3' },    // Magenta      
  { name: 7, hex: '#FF851B' },    // Orange
  { name: 8, hex: '#87D8F1' },    // Sky Blue
  { name: 9, hex: '#921231' }     // Burgundy
];

const colorIndexToHex = colors.reduce((acc, color) => {
  acc[color.name] = color.hex;
  return acc;
}, {});

const hexToColorIndex = colors.reduce((acc, color) => {
  acc[color.hex] = color.name;
  return acc;
}, {});

const Card = ({ title, onUpdate, isTestCard, initialData }) => {
  const [inputColors, setInputColors] = useState({});
  const [outputColors, setOutputColors] = useState({});
  const [inputDim, setInputDim] = useState({ rows: 5, cols: 5 })
  const [outputDim, setOutputDim] = useState({ rows: 5, cols: 5 })
  const [selectedColor, setSelectedColor] = useState('#1E93FF');
  const [inputColorMap, setInputColorMap] = useState([])
  const [outputColorMap, setOutputColorMap] = useState([])
  const [isDragging, setIsDragging] = useState(false);
  const [lastColoredCell, setLastColoredCell] = useState(null);

  useEffect(() => {
    if (initialData) {
      const convertGridToColors = (grid) => {
        return grid.flatMap((row, i) =>
          row.map((cell, j) => ({ [`${i * row.length + j}`]: colorIndexToHex[cell] || '#000000' }))
        ).reduce((acc, obj) => ({ ...acc, ...obj }), {});
      };

      setInputColors(convertGridToColors(initialData.input));
      setOutputColors(convertGridToColors(initialData.output));
      setInputDim({ rows: initialData.input.length, cols: initialData.input[0].length });
      setOutputDim({ rows: initialData.output.length, cols: initialData.output[0].length });
    }
  }, [initialData]);

  useEffect(() => {
    updateColorMap(inputDim, inputColors, setInputColorMap);
  }, [inputColors, inputDim]);

  useEffect(() => {
    updateColorMap(outputDim, outputColors, setOutputColorMap);
  }, [outputColors, outputDim]);

  useEffect(() => {
    onUpdate({ input: inputColorMap, output: outputColorMap });
  }, [inputColorMap, outputColorMap, onUpdate]);

  const updateColorMap = (dim, colors, setColorMap) => {
    const newColorMap = Array(dim.rows).fill().map(() => Array(dim.cols).fill(0));
    Object.entries(colors).forEach(([cellId, colorHex]) => {
      const row = Math.floor(parseInt(cellId) / dim.cols);
      const col = parseInt(cellId) % dim.cols;
      newColorMap[row][col] = hexToColorIndex[colorHex] || 0; 
    });
    setColorMap(newColorMap);
  };

  const handleCellColor = useCallback((cellId, isInput, isDragging) => {
    const setColors = isInput ? setInputColors : setOutputColors;
    setColors(prev => {
      let newColor;
      if (isDragging) {
        // When dragging, always apply the selected color
        newColor = selectedColor;
      } else {
        // When clicking, toggle between selected color and black
        newColor = prev[cellId] === selectedColor ? '#000000' : selectedColor;
      }
      // Only update if the color is actually changing
      if (prev[cellId] !== newColor) {
        return {
          ...prev,
          [cellId]: newColor
        };
      }
      // If the color isn't changing, return the previous state
      return prev;
    });
  }, [selectedColor, setInputColors, setOutputColors]);

  const handleCellClick = useCallback((cellId, isInput) => {
    handleCellColor(cellId, isInput, false);
  }, [handleCellColor]);

  const handleCellEnter = useCallback((cellId, isInput) => {
    if (isDragging && cellId !== lastColoredCell) {
      handleCellColor(cellId, isInput, true);
    }
  }, [isDragging, lastColoredCell, handleCellColor]);

  const handleMouseDown = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    return () => window.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseUp]);

  useEffect(() => {
    window.addEventListener('mousedown', handleMouseDown);
    return () => window.removeEventListener('mousedown', handleMouseDown);
  }, [handleMouseDown]);
  
  const handleDimChange = (type, value, isInput) => {
    const setDim = isInput ? setInputDim : setOutputDim;
    const setColors = isInput ? setInputColors : setOutputColors;
    setDim(prev => ({ ...prev, [type]: value }));
    setColors({});
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  const handleCopyInputToOutput = () => {
    setOutputDim({ ...inputDim });
    setOutputColors({ ...inputColors });
  };

  const handleResetGrid = (isInput) => {
    const setColors = isInput ? setInputColors : setOutputColors;
    setColors({});
  };

  const reflectGrid = (isInput, axis) => {
    const setColors = isInput ? setInputColors : setOutputColors;
    const dim = isInput ? inputDim : outputDim;
    
    setColors(prev => {
      const newColors = {};
      for (let i = 0; i < dim.rows; i++) {
        for (let j = 0; j < dim.cols; j++) {
          const oldIndex = i * dim.cols + j;
          let newIndex;
          if (axis === 'x') {
            newIndex = (dim.rows - 1 - i) * dim.cols + j;
          } else {
            newIndex = i * dim.cols + (dim.cols - 1 - j);
          }
          newColors[newIndex.toString()] = prev[oldIndex.toString()] || '#000000';
        }
      }
      return newColors;
    });
  };

  const rotateGrid = (isInput) => {
    const setColors = isInput ? setInputColors : setOutputColors;
    const dim = isInput ? inputDim : outputDim;
    const setDim = isInput ? setInputDim : setOutputDim;
    
    setColors(prev => {
      const newColors = {};
      for (let i = 0; i < dim.rows; i++) {
        for (let j = 0; j < dim.cols; j++) {
          const oldIndex = i * dim.cols + j;
          const newIndex = j * dim.rows + (dim.rows - 1 - i);
          newColors[newIndex.toString()] = prev[oldIndex.toString()] || '#000000';
        }
      }
      return newColors;
    });

    // Swap rows and columns
    setDim(prev => ({ rows: prev.cols, cols: prev.rows }));
  };

  const iconStyle = {
    width: '16px',
    height: '16px',
    verticalAlign: 'middle'
  };

  const unicodeIconStyle = {
    width: '16px',
    height: '16px',
    fontSize: '12px',
    marginLeft: '1px',
    verticalAlign: 'middle'
  }

  return (
    <div className={`card ${isTestCard ? 'test-card' : ''}`}>
      <h2 className="card-title">{title}</h2>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', margin: '20px' }}>
        <div style={{ width: '45%', margin: '10px'}}>
          <h3>Input:</h3>
          <div className={`grid-wrapper`}>
            <Grid 
              rows={inputDim.rows} 
              cols={inputDim.cols} 
              onCellClick={(cellId) => handleCellClick(cellId, true)}
              onCellEnter={(cellId) => handleCellEnter(cellId, true)}
              cellColors={inputColors}
            />
          </div>
          <GridDimensionSelector
            rows={inputDim.rows}
            cols={inputDim.cols}
            onDimensionChange={(type, value) => handleDimChange(type, value, true)}
          />
          <div className="button-group">
            {/* Clear Button */}
            <button className="card-button" onClick={handleResetGrid} title="Clear Grid">
              <svg style={iconStyle} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              </svg>
            </button>
            {/* Reflection over X-Axis Button */}
            <button className='card-button' onClick={() => reflectGrid(true, 'x')} title="Reflect over X-axis">
              <span style={unicodeIconStyle}>↕️</span>
            </button>
            {/* Reflection over Y-Axis Button */}
            <button className='card-button' onClick={() => reflectGrid(true, 'y')} title="Reflect over Y-axis">
              <span style={unicodeIconStyle}>↔️</span>
            </button>
            {/* Rotate Button */}
            <button className='card-button' onClick={() => rotateGrid(true)} title="Rotate 90°">
              <svg style={iconStyle} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="23 4 23 10 17 10"></polyline>
                <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path>
              </svg>
            </button>
          </div>
        </div>
        <div style={{ width: '45%', margin: '10px'}}>
          <h3>Output:</h3>
          <div className={`grid-wrapper`}>
            <Grid 
              rows={outputDim.rows} 
              cols={outputDim.cols} 
              onCellClick={(cellId) => handleCellClick(cellId, false)}
              onCellEnter={(cellId) => handleCellEnter(cellId, false)}
              cellColors={outputColors}
            />
          </div>
          <GridDimensionSelector
            rows={outputDim.rows}
            cols={outputDim.cols}
            onDimensionChange={(type, value) => handleDimChange(type, value, false)}
          />
          <div className="button-group">
            {/* Clear Button */}
            <button className="card-button" onClick={() => handleResetGrid(false)} title="Clear Grid">
              <svg style={iconStyle} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              </svg>
            </button>
            {/* Copy Button */}
            <button className="card-button" onClick={handleCopyInputToOutput} title="Copy from Input">
              <svg style={iconStyle} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                <path d="M18 2l4 4-10 10H8v-4L18 2z"></path>
              </svg>
            </button>
            {/* Reflection over X-Axis Button */}
            <button className='card-button' onClick={() => reflectGrid(false, 'x')} title="Reflect over X-axis">
              <span style={unicodeIconStyle}>↕️</span>
            </button>
            {/* Reflection over Y-Axis Button */}
            <button className='card-button' onClick={() => reflectGrid(false, 'y')} title="Reflect over Y-axis">
              <span style={unicodeIconStyle}>↔️</span>
            </button>
            {/* Rotate Button */}
            <button className='card-button' onClick={() => rotateGrid(false)} title="Rotate 90°">
              <svg style={iconStyle} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="23 4 23 10 17 10"></polyline>
                <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div style={{ width: '100%', marginTop: '20px' }}>
        <ColorSelector
          selectedColor={selectedColor}
          onColorSelect={handleColorSelect}
        />
      </div>
    </div>
  );
};

export default Card;