import React from 'react';
import Navbar from '../components/common/navbar'
import Footer from '../components/common/footer'
import '../components/ui/ui.css'

const About = ({ onBack }) => {
  return (
    <div className='app'>
      <Navbar 
        onBack={onBack}
      />
      <div className="container">
        <div className="about-container">
          <h2>Mission of this Playground</h2>
          <p>
            The mission is to foster creativity and innovation in the field of AI by encouraging the development of human-designed ARC-AGI puzzles that push 
            the boundaries of intelligence. By engaging a global community of puzzle creators and solvers, 
            we aim to make significant strides toward the development of AGI by compiling human-designed puzzles into a large public dataset. 
          </p>
          <h2>About the ARC-AGI Prize</h2>
          <p>
            The ARC-AGI Prize is a $1,000,000+ public competition aimed at advancing the field of Artificial General Intelligence (AGI). 
            It challenges participants to beat and open source a solution to the ARC-AGI benchmark.
          </p>

          <h2>Creators</h2>
          <p>
            The ARC AGI Prize Competition is hosted by Mike Knoop (Co-founder, Zapier) and François Chollet (Creator of ARC-AGI, Keras).
          </p>
          <h2>Learn More</h2>
          <p>
            For more information about the ARC-AGI Prize, visit the official
              <a href="https://arcprize.org" target="_blank" rel="noopener noreferrer">website</a>.<br />
            For bug reports or feature requests, please reach out to cjchristianjones at gmail dot com.
          </p>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
