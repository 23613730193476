import React from 'react';

// Reverse Engineered from Competition Examples
const colors = [
  { name: 0, hex: '#000000' },    // Black
  { name: 1, hex: '#1E93FF' },    // Blue
  { name: 2, hex: '#F93C31' },    // Red
  { name: 3, hex: '#4FCC30' },    // Green
  { name: 4, hex: '#FFDC00' },    // Yellow
  { name: 5, hex: '#999999' },    // Grey
  { name: 6, hex: '#E53AA3' },    // Magenta      
  { name: 7, hex: '#FF851B' },    // Orange
  { name: 8, hex: '#87D8F1' },    // Sky Blue
  { name: 9, hex: '#921231' }     // Burgundy
];

const ColorSelector = ({ selectedColor, onColorSelect }) => {
  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      flexWrap: 'wrap', 
      gap: '10px',
      marginTop: '20px'
    }}>
      {colors.map((color) => (
        <button
          key={color.name}
          onClick={() => onColorSelect(color.hex)}
          style={{
            width: '30px',
            height: '30px',
            backgroundColor: color.hex,
            border: selectedColor === color.hex ? '3px solid #000' : '1px solid #ccc',
            borderRadius: '50%',
            cursor: 'pointer',
            outline: 'none'
          }}
          title={color.name}
        />
      ))}
    </div>
  );
};

export default ColorSelector;