import React, { useState, useEffect, useCallback } from 'react';
import './ui.css'
import Grid from './grid';
import ColorSelector from './color-selector';
import GridDimensionSelector from './grid-dim-selector';

const colors = [
  { name: 0, hex: '#000000' },    // Black
  { name: 1, hex: '#1E93FF' },    // Blue
  { name: 2, hex: '#F93C31' },    // Red
  { name: 3, hex: '#4FCC30' },    // Green
  { name: 4, hex: '#FFDC00' },    // Yellow
  { name: 5, hex: '#999999' },    // Grey
  { name: 6, hex: '#E53AA3' },    // Magenta      
  { name: 7, hex: '#FF851B' },    // Orange
  { name: 8, hex: '#87D8F1' },    // Sky Blue
  { name: 9, hex: '#921231' }     // Burgundy
];

const hexToColorIndex = colors.reduce((acc, color) => {
  acc[color.hex] = color.name;
  return acc;
}, {});

const colorIndexToHex = colors.reduce((acc, color) => {
  acc[color.name] = color.hex;
  return acc;
}, {});

const PlaytestCard = ({ data }) => {
  const [inputColors, setInputColors] = useState({});
  const [outputColors, setOutputColors] = useState({});
  const [selectedColor, setSelectedColor] = useState('#1E93FF');
  const [cardState, setCardState] = useState('default'); // 'default', 'correct', or 'incorrect'
  const [outputDim, setOutputDim] = useState({ rows: data.output.length, cols: data.output[0].length })
  const [isDragging, setIsDragging] = useState(false);
  const [lastColoredCell, setLastColoredCell] = useState(null);

  // Cell Coloring Functionality
  const handleCellColor = useCallback((cellId, isDragging) => {
    setOutputColors(prev => {
      let newColor;
      if (isDragging) {
        // When dragging, always apply the selected color
        newColor = selectedColor;
      } else {
        // When clicking, toggle between selected color and black
        newColor = prev[cellId] === selectedColor ? '#000000' : selectedColor;
      }
      // Only update if the color is actually changing
      if (prev[cellId] !== newColor) {
        return {
          ...prev,
          [cellId]: newColor
        };
      }
      // If the color isn't changing, return the previous state
      return prev;
    });
  }, [selectedColor]);

  const handleCellClick = useCallback((cellId) => {
    handleCellColor(cellId, false);
  }, [handleCellColor]);

  const handleCellEnter = useCallback((cellId) => {
    if (isDragging && cellId !== lastColoredCell) {
      handleCellColor(cellId, true);
      setLastColoredCell(cellId);
    }
  }, [isDragging, lastColoredCell, handleCellColor]);

  const handleMouseDown = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    setLastColoredCell(null);
  }, []);

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  const handleDimChange = (type, value) => {
    setOutputDim(prev => {
      const newDim = { ...prev, [type]: value };
      setOutputColors({}); // Clear colors when dimensions change
      return newDim;
    });
  };

  const handleSubmit = () => {
    const isCorrect = checkSolution();
    setCardState(isCorrect ? 'correct' : 'incorrect');
    setTimeout(() => setCardState('default'), 2000); // Reset the card state after 2 seconds
  };

  const handleCopyInputToOutput = () => {
    setOutputDim({ rows: data.input.length, cols: data.input[0].length });
    setOutputColors({ ...inputColors });
  };

  const handleResetGrid = () => {
    setOutputColors({});
  };

  // Side Effect functionalities
  useEffect(() => {
    setInputColors(
      data.input.flatMap((row, i) =>
        row.map((cell, j) => ({ [`${i * row.length + j}`]: colorIndexToHex[cell] }))
      ).reduce((acc, obj) => ({ ...acc, ...obj }), {})
    );
    setOutputDim({ rows: data.output.length, cols: data.output[0].length });
    setOutputColors({});
  }, [data]);

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    return () => window.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseUp]);

  useEffect(() => {
    window.addEventListener('mousedown', handleMouseDown);
    return () => window.removeEventListener('mousedown', handleMouseDown);
  }, [handleMouseDown]);


  // Operation Functions
  const reflectGrid = (axis) => {
    setOutputColors(prev => {
      const newColors = {};
      for (let i = 0; i < outputDim.rows; i++) {
        for (let j = 0; j < outputDim.cols; j++) {
          const oldIndex = i * outputDim.cols + j;
          let newIndex;
          if (axis === 'x') {
            newIndex = (outputDim.rows - 1 - i) * outputDim.cols + j;
          } else {
            newIndex = i * outputDim.cols + (outputDim.cols - 1 - j);
          }
          newColors[newIndex.toString()] = prev[oldIndex.toString()] || '#000000';
        }
      }
      return newColors;
    });
  };

  const rotateGrid = () => {
    setOutputColors(prev => {
      const newColors = {};
      for (let i = 0; i < outputDim.rows; i++) {
        for (let j = 0; j < outputDim.cols; j++) {
          const oldIndex = i * outputDim.cols + j;
          const newIndex = j * outputDim.rows + (outputDim.rows - 1 - i);
          newColors[newIndex.toString()] = prev[oldIndex.toString()] || '#000000';
        }
      }
      return newColors;
    });

    // Swap rows and columns
    setOutputDim(prev => ({ rows: prev.cols, cols: prev.rows }));
  };

  const checkSolution = () => {
    if (outputDim.rows !== data.output.length || outputDim.cols !== data.output[0].length) {
      return false; // Dimensions don't match, so it can't be correct
    }

    const userSolution = Array(outputDim.rows).fill().map(() => Array(outputDim.cols).fill(0));
    Object.entries(outputColors).forEach(([cellId, colorHex]) => {
      const row = Math.floor(parseInt(cellId) / outputDim.cols);
      const col = parseInt(cellId) % outputDim.cols;
      userSolution[row][col] = hexToColorIndex[colorHex] || 0; // Use 0 (black) if colorHex is undefined
    });

    return JSON.stringify(userSolution) === JSON.stringify(data.output);
  };

  const iconStyle = {
    width: '16px',
    height: '16px',
    verticalAlign: 'middle'
  };

  const unicodeIconStyle = {
    width: '16px',
    height: '16px',
    fontSize: '12px',
    marginLeft: '1px',
    verticalAlign: 'middle'
  }

  return (
    <div className={`playtest-card ${cardState}`}>
      <h2 className="card-title">Test Puzzle</h2>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', margin: '20px' }}>
        <div style={{ width: '45%', margin: '10px'}}>
          <h3>Input:</h3>
          <Grid
            rows={data.input.length}
            cols={data.input[0].length}
            cellColors={inputColors}
            isEditable={false}
          />
        </div>
        <div style={{ width: '45%', margin: '10px'}}>
          <h3>Output:</h3>
          <Grid
            rows={outputDim.rows}
            cols={outputDim.cols}
            cellColors={outputColors}
            onCellClick={handleCellClick}
            onCellEnter={handleCellEnter}
            isEditable={true}
          />
          <GridDimensionSelector
            rows={outputDim.rows}
            cols={outputDim.cols}
            onDimensionChange={(type, value) => handleDimChange(type, value)}
          />
          <div className="button-group">
            {/* Clear Button */}
            <button className="card-button" onClick={handleResetGrid} title="Clear Grid">
              <svg style={iconStyle} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              </svg>
            </button>
            {/* Copy Button */}
            <button className="card-button" onClick={handleCopyInputToOutput} title="Copy from Input">
              <svg style={iconStyle} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                <path d="M18 2l4 4-10 10H8v-4L18 2z"></path>
              </svg>
            </button>
            {/* Reflection over X-Axis Button */}
            <button className='card-button' onClick={() => reflectGrid('x')} title="Reflect over X-axis">
              <span style={unicodeIconStyle}>↕️</span>
            </button>
            {/* Reflection over Y-Axis Button */}
            <button className='card-button' onClick={() => reflectGrid('y')} title="Reflect over Y-axis">
              <span style={unicodeIconStyle}>↔️</span>
            </button>
            {/* Rotate Button */}
            <button className='card-button' onClick={rotateGrid} title="Rotate 90°">
              <svg style={iconStyle} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="23 4 23 10 17 10"></polyline>
                <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path>
              </svg>
            </button>
            {/* Submit Button */}
            <button className="card-button" onClick={handleSubmit} title="Submit Solution">
              <svg style={iconStyle} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <ColorSelector
        selectedColor={selectedColor}
        onColorSelect={handleColorSelect}
      />
    </div>
  );
};

export default PlaytestCard;