// Divider.js
import React from 'react';

const Divider = () => {
  return (
    <div 
      style={{
        width: '85%',
        height: '1px',
        backgroundColor: 'white',
        margin: '20px 0',
        borderRadius: '8px',
        opacity: '25%'
      }}
    ></div>
  );
};

export default Divider;
