import React, { useState, useEffect } from 'react';
import './ui.css';

const MetadataCard = ({ onMetadataChange }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const [username, setUsername] = useState('');

  useEffect(() => {
    onMetadataChange({ title, description, category, tags, username });
  }, [title, description, category, tags, username, onMetadataChange]);

  const handleTagKeyDown = (e) => {
    if (e.key === 'Enter' && tagInput) {
      e.preventDefault();
      if (!tags.includes(tagInput)) {
        setTags([...tags, tagInput]);
        setTagInput('');
      }
    }
  };

  const handleTagDelete = (tagToDelete) => {
    setTags(tags.filter(tag => tag !== tagToDelete));
  };

  return (
    <div className="metadata-card">
      <form>
        <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            placeholder="Enter your username..."
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
        />

        <label htmlFor="puzzle-title">Puzzle Title</label>
        <input
          type="text"
          id="puzzle-title"
          name="puzzle-title"
          placeholder="Enter puzzle title..."
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        
        <label htmlFor="puzzle-description">Puzzle Description</label>
        <textarea
          id="puzzle-description"
          name="puzzle-description"
          placeholder="Enter puzzle description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
        
        <label htmlFor="category">Category</label>
        <select
          id="category"
          name="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          required
        >
          <option value="">Select category</option>
          <option value="pattern-recognition">Pattern Recognition</option>
          <option value="logical-reasoning">Logical Reasoning</option>
          <option value="spatial-reasoning">Spatial Reasoning</option>
          <option value="color-matching">Color Matching</option>
          <option value="temporal-reasoning">Temporal Reasoning</option>
          <option value="compositional-reasoning">Compositional Reasoning</option>
          <option value="rule-induction">Rule Induction</option>
          <option value="arithmetic-reasoning">Arithmetic Reasoning</option>
          <option value="constraint-satisfaction">Constraint Satisfaction</option>
          <option value="contextual-reasoning">Contextual Reasoning</option>
          <option value="other">Other</option>
        </select>
        
        <label htmlFor="tags">Tags</label>
        <div className="tags-input">
          <input
            type="text"
            id="tags"
            name="tags"
            placeholder="Enter tags and press Enter"
            value={tagInput}
            onChange={(e) => setTagInput(e.target.value)}
            onKeyDown={handleTagKeyDown}
          />
          <div>
            {tags.map((tag, index) => (
              <div className="tag" key={index}>
                <span>{tag}</span>
                <button type="button" onClick={() => handleTagDelete(tag)}>x</button>
              </div>
            ))}
          </div>
        </div>
      </form>
    </div>
  );
};

export default MetadataCard;