import React, { useState, useEffect } from 'react';
import Navbar from '../components/common/navbar';
import BrowseCard from '../components/ui/browse-card';
import '../components/ui/ui.css'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const SuccessPage = ({ puzzleId, onBack, onCreatePuzzle, onPlayPuzzle }) => {
  const [puzzle, setPuzzle] = useState(null);

  useEffect(() => {
    const fetchPuzzle = async () => {
      try {
        // Update this URL to match your API endpoint
        const response = await fetch(`${API_URL}/puzzles/search?query=${puzzleId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch puzzle');
        }
        const data = await response.json();
        // Assuming the search returns an array of puzzles, we take the first one
        setPuzzle(data.puzzles[0]);
      } catch (error) {
        console.error('Error fetching puzzle:', error);
        // Handle error (e.g., show an error message)
      }
    };

    if (puzzleId) {
      fetchPuzzle();
    }
  }, [puzzleId]);

  const handlePlayPuzzle = () => {
    if (puzzle) {
      onPlayPuzzle(puzzle);
    }
  };

  return (
    <div className="app">
      <Navbar 
        onBack={onBack}
        onCreatePuzzle={onCreatePuzzle}
      />
      <div className="container">
        <div className="success-container">
          <h1 className="text-center">Puzzle Uploaded Successfully!</h1>
          <p className="text-center">Your puzzle ID is: {puzzleId.slice(0,8)}</p>
          {puzzle ? (
            <div>
              <BrowseCard puzzle={puzzle} onPlayPuzzle={handlePlayPuzzle} />
            </div>
          ) : (
            <p>Loading puzzle preview...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;