import React from 'react';
import Grid from './grid';
import Arrow from './arrow';
import './ui.css';

const BrowseCard = ({ puzzle, onPlayPuzzle }) => {
  const {
    puzzleId,
    title,
    description,
    creatorUsername,
    category,
    tags,
    train
  } = puzzle;

  const colors = [
    { name: 0, hex: '#000000' },    // Black
    { name: 1, hex: '#1E93FF' },    // Blue
    { name: 2, hex: '#F93C31' },    // Red
    { name: 3, hex: '#4FCC30' },    // Green
    { name: 4, hex: '#FFDC00' },    // Yellow
    { name: 5, hex: '#999999' },    // Grey
    { name: 6, hex: '#E53AA3' },    // Magenta      
    { name: 7, hex: '#FF851B' },    // Orange
    { name: 8, hex: '#87D8F1' },    // Sky Blue
    { name: 9, hex: '#921231' }     // Burgundy
  ];

  const getColoredGrid = (grid) => {
    return grid.flat().reduce((acc, cellValue, index) => {
      const color = colors.find(c => c.name === cellValue) || colors[0];
      acc[index.toString()] = color.hex;
      return acc;
    }, {});
  };

  return (
    <div className="browse-card">
      <div className="browse-card-content">
        <div className="browse-card-info">
          <h2 className="browse-card-title">{title}</h2>
          <p className="browse-card-description">{description}</p>
          {creatorUsername && <p className="browse-card-creator">Created by: {creatorUsername}</p>}
          <p className="browse-card-category">Category: {category}</p>
          <p className="browse-card-id">Puzzle ID: {puzzleId.substring(0, 8)}</p>
          {tags && tags.length > 0 && (
            <div className="browse-card-tags">
              {tags.map((tag, index) => (
                <span key={index} className="browse-card-tag">{tag}</span>
              ))}
            </div>
          )}
          <button className="browse-card-button" onClick={() => onPlayPuzzle(puzzle)} aria-label={`Play ${title} puzzle`}>
            Play Now
          </button>
        </div>
        {train && train[0] && (
          <div className="browse-card-puzzles">
            <div className="browse-card-puzzle">
              <div className="browse-card-puzzle-grid">
                <Grid
                  rows={train[0].input.length}
                  cols={train[0].input[0].length}
                  cellColors={getColoredGrid(train[0].input)}
                  isEditable={false}
                />
              </div>
            </div>
            <Arrow />
            <div className="browse-card-puzzle">
              <div className="browse-card-puzzle-grid">
                <Grid
                  rows={train[0].output.length}
                  cols={train[0].output[0].length}
                  cellColors={getColoredGrid(train[0].output)}
                  isEditable={false}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BrowseCard;