import React, { useState, useEffect, useRef } from 'react';
import Grid from './grid';
import Arrow from './arrow';
import './ui.css';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const BrowseCardEval = ({ onPlayPuzzle }) => {
  const [puzzleId, setPuzzleId] = useState('');
  const [currentPuzzle, setCurrentPuzzle] = useState(null);
  const [loading, setLoading] = useState(false);
  const isInitialMount = useRef(true);

  // Define the color array
  const colors = [
    { name: 0, hex: '#000000' },    // Black
    { name: 1, hex: '#1E93FF' },    // Blue
    { name: 2, hex: '#F93C31' },    // Red
    { name: 3, hex: '#4FCC30' },    // Green
    { name: 4, hex: '#FFDC00' },    // Yellow
    { name: 5, hex: '#999999' },    // Grey
    { name: 6, hex: '#E53AA3' },    // Magenta      
    { name: 7, hex: '#FF851B' },    // Orange
    { name: 8, hex: '#87D8F1' },    // Sky Blue
    { name: 9, hex: '#921231' }     // Burgundy
  ];

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      handleRandomize();
    }
  }, []);

  const handleInputChange = (e) => {
    setPuzzleId(e.target.value);
  };

  const handleLoadPuzzle = async () => {
    if (puzzleId) {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}/puzzles/arc-agi/${puzzleId}`);
        if (!response.ok) {
          throw new Error('Puzzle not found');
        }
        const puzzle = await response.json();
        setCurrentPuzzle(puzzle);
      } catch (error) {
        alert('Error loading puzzle: ' + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRandomize = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/puzzles/arc-agi/random`);
      if (!response.ok) {
        throw new Error('Failed to fetch random puzzle');
      }
      const puzzle = await response.json();
      setCurrentPuzzle(puzzle);
    } catch (error) {
      alert('Error loading random puzzle: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePlayNow = () => {
    if (currentPuzzle) {
      onPlayPuzzle(currentPuzzle);
    }
  };

  const getColoredGrid = (grid) => {
    return grid.flat().reduce((acc, cellValue, index) => {
      const color = colors.find(c => c.name === cellValue) || colors[0];
      acc[index.toString()] = color.hex;
      return acc;
    }, {});
  };

  return (
    <div className="browse-card">
      <div className="browse-card-content">
        <div className="browse-card-info">
          <h2 className="browse-card-title">ARC-AGI Puzzle: {currentPuzzle ? currentPuzzle.puzzle_id : ''}</h2>
          <p className="browse-card-description">
            Test your visual and logical skills with this puzzle from the ARC-AGI dataset.
          </p>
          <button onClick={handlePlayNow} className="browse-card-button" disabled={!currentPuzzle || loading}>
            Play Now
          </button>
          <button onClick={handleRandomize} className="browse-card-button" disabled={loading}>
            Randomize
          </button>
          <div className="browse-card-input">
            <button onClick={handleLoadPuzzle} className="browse-card-button" disabled={!puzzleId || loading}>
              Load
            </button>
            <input
              type="text"
              placeholder="Puzzle ID..."
              value={puzzleId}
              onChange={handleInputChange}
            />
          </div>
        </div>
        {currentPuzzle && (
          <div className="browse-card-puzzles">
            <div className="browse-card-puzzle">
              <div className="browse-card-puzzle-grid">  
                <Grid
                  rows={currentPuzzle.train[0].input.length}
                  cols={currentPuzzle.train[0].input[0].length}
                  cellColors={getColoredGrid(currentPuzzle.train[0].input)}
                  isEditable={false}
                />
            </div>    
            </div>
            <Arrow />
            <div className="browse-card-puzzle">
              <div className="browse-card-puzzle-grid">
                <Grid
                  rows={currentPuzzle.train[0].output.length}
                  cols={currentPuzzle.train[0].output[0].length}
                  cellColors={getColoredGrid(currentPuzzle.train[0].output)}
                  isEditable={false}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BrowseCardEval;