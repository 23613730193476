import React from 'react';

const EmptyPlayCard = ({ onUpload, onBack }) => {

  return (
    <div className="card empty-play-card">
      <h2 className="card-title">No Puzzle Loaded</h2>
      <p>It looks like you haven't selected a puzzle to play yet.</p>
      <div className="button-group">
        <button className="card-button" onClick={onBack}>
          Find a Puzzle
        </button>
        <label className="card-button" htmlFor="file-upload">
          Upload a Puzzle
          <input
            id="file-upload"
            type="file"
            accept=".json"
            onChange={onUpload}
            style={{ display: 'none' }}
          />
        </label>
      </div>
    </div>
  );
};

export default EmptyPlayCard;