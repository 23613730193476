import React from 'react';

const Arrow = ({ direction = 'right', color = 'white', size = 24 }) => {
  const styles = {
    arrow: {
      width: 0,
      height: 0,
      borderTop: `${size / 2}px solid transparent`,
      borderBottom: `${size / 2}px solid transparent`,
      borderLeft: direction === 'right' ? `${size}px solid ${color}` : 'none',
      borderRight: direction === 'left' ? `${size}px solid ${color}` : 'none',
      display: 'inline-block',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 10px',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.arrow}></div>
    </div>
  );
};

export default Arrow;