import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './common.css'

const Navbar = ({ 
  onCreatePuzzle, 
  onPlayPuzzle, 
  onAbout, 
  onBack,
  onExport,
  onAddExample,
  onRemoveExample,
  canAddExample,
  canRemoveExample,
  onUpload,
  onUploadToDatabase,
  onReturnToCreate,
  showLoginRegister = false
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <nav className="navbar">
      <div className="navbar-section navbar-left">
        <div className="navbar-title">ARC-AGI Playground</div>
      </div>
      <div className="navbar-section navbar-center">
        <div className="navbar-links">
          {onCreatePuzzle && <a href='#' className="navbar-link" onClick={onCreatePuzzle}>Create Puzzle</a>}
          {onPlayPuzzle && <a href='#' className="navbar-link" onClick={onPlayPuzzle}>Play Puzzle</a>}
          {onAbout && <a href='#' className="navbar-link" onClick={onAbout}>About</a>}
          {onBack && <a href='#' className="navbar-link" onClick={onBack}>Back to Menu</a>}
          {onExport && <a href='#' className="navbar-link" onClick={onExport} title="Export Puzzle to JSON">Export Puzzle</a>}
          {onUpload && (
            <label htmlFor="file-upload" className="navbar-link">
              Upload Puzzle
              <input 
                id="file-upload" 
                type="file" 
                accept=".json" 
                onChange={onUpload} 
                style={{ display: 'none' }}
              />
            </label>
          )}
          {onUploadToDatabase && (
            <a href='#' className="navbar-link" onClick={onUploadToDatabase}>
              Upload Puzzle
            </a>
          )}
          {onAddExample && (
            <a href='#' className="navbar-link" onClick={onAddExample} style={{ opacity: canAddExample ? 1 : 0.5 }}>
              Add Example
            </a>
          )}
          {onRemoveExample && (
            <a href='#' className="navbar-link" onClick={onRemoveExample} style={{ opacity: canRemoveExample ? 1 : 0.5 }}>
              Remove Example
            </a>
          )}
          {onReturnToCreate && <a href="#" className="navbar-link" onClick={onReturnToCreate}>Create Puzzle</a>}
        </div>
      </div>
      <div className="navbar-section navbar-right">
        {showLoginRegister && (
          <div className="navbar-auth">
            <button className="navbar-link" onClick={() => setShowDropdown(!showDropdown)}>
              Account
            </button>
            {showDropdown && (
              <div className="navbar-dropdown">
                <Link to="/login" className="navbar-dropdown-item">Login</Link>
                <Link to="/register" className="navbar-dropdown-item">Register</Link>
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;