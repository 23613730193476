import React from 'react';
import BrowseCard from './browse-card';
import './ui.css';

const SearchResults = ({ puzzles, onPlayPuzzle, onLoadMore, hasMore }) => {
  return (
    <div className="search-results">
      <h2 className="search-results-title">Search Results</h2>
      {puzzles.length === 0 ? (
        <div className="no-results-message">
          <p>No puzzles found. Try a different search term or category.</p>
        </div>
      ) : (
        <>
          <div className="search-results-grid">
            {puzzles.map(puzzle => (
              <BrowseCard key={puzzle.puzzleId} puzzle={puzzle} onPlayPuzzle={onPlayPuzzle} />
            ))}
          </div>
          {hasMore && (
            <div className="load-more-container">
              <button className="load-more-button" onClick={onLoadMore}>
                Load More
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SearchResults;