import React, { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import './ui.css'

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');
  const [category, setCategory] = useState('');

  const debouncedSearch = useCallback(
    debounce((q, c) => {
      console.log('Searching for:', q, 'in category:', c);
      onSearch(q, c);
    }, 300),
    [onSearch]
  );

  const handleQueryChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    debouncedSearch(newQuery, category);
  };

  const handleCategoryChange = (e) => {
    const newCategory = e.target.value;
    setCategory(newCategory);
    debouncedSearch(query, newCategory);
  };

  return (
    <div className="search-bar-container">
      <h2 className="search-title">Search Puzzles</h2>
      <div className="search-bar">
        <input 
          type="text" 
          placeholder="Search by keyword, tag, or puzzle ID..." 
          value={query}
          onChange={handleQueryChange}
        />
        <select 
          className="search-bar-select"
          value={category} 
          onChange={handleCategoryChange}
        >
          <option value="">All Categories</option>
          <option value="pattern-recognition">Pattern Recognition</option>
          <option value="logical-reasoning">Logical Reasoning</option>
          <option value="spatial-reasoning">Spatial Reasoning</option>
          <option value="color-matching">Color Matching</option>
          <option value="temporal-reasoning">Temporal Reasoning</option>
          <option value="compositional-reasoning">Compositional Reasoning</option>
          <option value="rule-induction">Rule Induction</option>
          <option value="arithmetic-reasoning">Arithmetic Reasoning</option>
          <option value="constraint-satisfaction">Constraint Satisfaction</option>
          <option value="contextual-reasoning">Contextual Reasoning</option>
          <option value="other">Other</option>
        </select>
      </div>
    </div>
  );
};

export default SearchBar;