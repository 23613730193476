import React from 'react';
import './ui.css'
import Grid from './grid';

const colors = [
  { name: 0, hex: '#000000' },    // Black
  { name: 1, hex: '#1E93FF' },    // Blue
  { name: 2, hex: '#F93C31' },    // Red
  { name: 3, hex: '#4FCC30' },    // Green
  { name: 4, hex: '#FFDC00' },    // Yellow
  { name: 5, hex: '#999999' },    // Grey
  { name: 6, hex: '#E53AA3' },    // Magenta      
  { name: 7, hex: '#FF851B' },    // Orange
  { name: 8, hex: '#87D8F1' },    // Sky Blue
  { name: 9, hex: '#921231' }     // Burgundy
];

const PlayCard = ({ title, data }) => {
  return (
    <div className='play-card'>
      <h2 className="card-title">{title}</h2>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', margin: '20px' }}>
        <div style={{ width: '45%', margin: '10px'}}>
          <h3>Input:</h3>
          <Grid
            rows={data.input.length}
            cols={data.input[0].length}
            cellColors={data.input.flatMap((row, i) =>
              row.map((cell, j) => ({ [`${i * row.length + j}`]: colors[cell].hex }))
            ).reduce((acc, obj) => ({ ...acc, ...obj }), {})}
            isEditable={false}
          />
        </div>
        <div style={{ width: '45%', margin: '10px'}}>
          <h3>Output:</h3>
          <Grid
            rows={data.output.length}
            cols={data.output[0].length}
            cellColors={data.output.flatMap((row, i) =>
              row.map((cell, j) => ({ [`${i * row.length + j}`]: colors[cell].hex }))
            ).reduce((acc, obj) => ({ ...acc, ...obj }), {})}
            isEditable={false}
          />
        </div>
      </div>
    </div>
  );
};

export default PlayCard;