import React, { useState } from 'react';
import './App.css';
import HomePage from '../pages/home';
import CreationPage from '../pages/creating';
import PlayPage from '../pages/playing';
import RegisterPage from '../pages/register';
import AboutPage from '../pages/about';
import SuccessPage from '../pages/success'; // Import the new SuccessPage component

const App = () => {
  const [currentView, setCurrentView] = useState('home');
  const [puzzleData, setPuzzleData] = useState(null);
  const [uploadedPuzzleId, setUploadedPuzzleId] = useState(null);

  const handleCreatePuzzle = () => {
    setCurrentView('create');
  };

  const handlePlayPuzzle = (selectedPuzzle = null) => {
    setPuzzleData(selectedPuzzle);
    setCurrentView('play');
  };

  const handleAbout = () => {
    setCurrentView('about');
  }

  const handleRegister = () => {
    setCurrentView('register');
  };

  const handleBackToHome = () => {
    setCurrentView('home');
    setPuzzleData(null);
  };

  const handleImportToPlaytest = (data) => {
    setPuzzleData(data);
    setCurrentView('play');
  };

  const handleReturnToCreate = (data) => {
    setPuzzleData(data);
    setCurrentView('create');
  };

  const handleUploadSuccess = (puzzleId) => {
    setUploadedPuzzleId(puzzleId);
    setCurrentView('success');
  };

  switch (currentView) {
    case 'home':
      return <HomePage 
        onCreatePuzzle={handleCreatePuzzle} 
        onPlayPuzzle={handlePlayPuzzle} 
        onAbout={handleAbout}
      />;
    case 'create':
      return <CreationPage 
        onBack={handleBackToHome} 
        onImportToPlaytest={handleImportToPlaytest} 
        initialPuzzleData={puzzleData} 
        onUploadSuccess={handleUploadSuccess}
      />;
    case 'play':
      return <PlayPage 
        onBack={handleBackToHome} 
        onReturnToCreate={handleReturnToCreate} 
        initialPuzzleData={puzzleData} 
      />;
    case 'register':
      return <RegisterPage onBack={handleBackToHome} />;
    case 'about':
      return <AboutPage onBack={handleBackToHome} />;
    case 'success':
      return <SuccessPage 
        puzzleId={uploadedPuzzleId}
        onBack={handleBackToHome}
        onCreatePuzzle={handleCreatePuzzle}
        onPlayPuzzle={handlePlayPuzzle}
      />;
    default:
      return <HomePage 
        onBack={handleBackToHome} 
        onCreatePuzzle={handleCreatePuzzle} 
        onPlayPuzzle={handlePlayPuzzle}
      />;
  }
};

export default App;