import React, { useState, useEffect } from 'react';
import Navbar from '../components/common/navbar'
import PlayCard from '../components/ui/playcard';
import PlaytestCard from '../components/ui/playtest-card';
import EmptyPlayCard from '../components/ui/playcard-empty';

const PlayPage = ({ onBack, onReturnToCreate, initialPuzzleData }) => {
  const [puzzle, setPuzzle] = useState(null);

  useEffect(() => {
    if (initialPuzzleData) {
      setPuzzle(initialPuzzleData);
    }
  }, [initialPuzzleData]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const json = JSON.parse(e.target.result);
        if (validatePuzzleJSON(json)) {
          setPuzzle(json);
        } else {
          alert('Invalid puzzle format. Please upload a valid JSON file.');
        }
      } catch (error) {
        alert('Error parsing JSON file. Please try again.');
      }
    };

    reader.readAsText(file);
  };

  const validatePuzzleJSON = (json) => {
    return (
      json.train &&
      Array.isArray(json.train) &&
      json.train.length >= 1 &&
      json.train.length <= 5 &&
      json.test &&
      Array.isArray(json.test) &&
      json.test.length === 1
    );
  };

  const handleReturnToCreate = () => {
    onReturnToCreate(puzzle);
  };

  return (
    <div className="app">
      <Navbar 
        onBack={onBack} 
        onReturnToCreate={handleReturnToCreate}
      />
      <div className="container">
        {!puzzle ? (
          <div className="file-upload-prompt">
            <EmptyPlayCard onUpload={handleFileUpload} onBack={onBack} />
          </div>
        ) : (
          <>
            {puzzle.train.map((example, index) => (
              <PlayCard key={index} title={`Example ${index + 1}`} data={example} />
            ))}
            <PlaytestCard title="Test" data={puzzle.test[0]} />
          </>       
        )}
      </div>
    </div>
  );
};

export default PlayPage;