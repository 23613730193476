import React from 'react';

const GridDimensionSelector = ({ rows, cols, onDimensionChange }) => {
  const options = Array.from({ length: 30 }, (_, i) => i + 1);

  return (
    <div className="grid-dimension-selector" style={{
      marginTop: '20px',
      padding: '15px',
      backgroundColor: '#3a3a3a',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px',
    }}>
      <label style={{ color: 'white' }}>
        Rows: {" "}
        <select
          value={rows}
          onChange={(e) => onDimensionChange('rows', parseInt(e.target.value, 10))}
        >
          {options.map(option => (
            <option key={`row-${option}`} value={option}>{option}</option>
          ))}
        </select>
      </label>
      <label style={{ color: 'white' }}>
        Columns: {" "} 
        <select
          value={cols}
          onChange={(e) => onDimensionChange('cols', parseInt(e.target.value, 10))}
        >
          {options.map(option => (
            <option key={`col-${option}`} value={option}>{option}</option>
          ))}
        </select>
      </label>
    </div>
  );
};

export default GridDimensionSelector;