import React, { useState, useEffect, useRef } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

const ResponsiveGridLayout = WidthProvider(Responsive);

const Grid = ({ rows, cols, onCellClick, onCellEnter, cellColors, isEditable = true }) => {
  const [layout, setLayout] = useState([]);
  const [gridDimensions, setGridDimensions] = useState({ width: 0, height: 0 });
  const gridRef = useRef(null);

  useEffect(() => {
    const newLayout = Array.from({ length: rows * cols }, (_, i) => ({
      i: i.toString(),
      x: i % cols,
      y: Math.floor(i / cols),
      w: 1,
      h: 1,
      static: true,
    }));
    setLayout(newLayout);
  }, [rows, cols]);

  useEffect(() => {
    const updateDimensions = () => {
      if (gridRef.current) {
        const containerWidth = gridRef.current.offsetWidth;
        const containerHeight = gridRef.current.offsetHeight;
        
        const cellSize = Math.min(containerWidth / cols, containerHeight / rows);
        const gridWidth = cellSize * cols;
        const gridHeight = cellSize * rows;

        setGridDimensions({ width: gridWidth, height: gridHeight });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [rows, cols]);

  const cellSize = gridDimensions.width / cols;

  return (
    <div 
      ref={gridRef} 
      className='grid-container' 
      style={{ 
        width: '100%', 
        paddingBottom: '100%', 
        position: 'relative' 
      }}
    >
      <div style={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)',
        width: `${gridDimensions.width}px`,
        height: `${gridDimensions.height}px`,
      }}>
        <ResponsiveGridLayout
          layout={layout}
          layouts={{ lg: layout }}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: cols, md: cols, sm: cols, xs: cols, xxs: cols }}
          rowHeight={cellSize}
          width={gridDimensions.width}
          margin={[0, 0]}
          containerPadding={[0, 0]}
          isDraggable={false}
          isResizable={false}
          compactType={null}
        >
          {layout.map((cell) => (
            <div
              key={cell.i}
              style={{
                border: '1px solid white',
                backgroundColor: cellColors[cell.i] || '#000000',
                cursor: isEditable ? 'pointer' : 'default',
                width: '100%',
                height: '100%'
              }}
              onClick={isEditable ? () => onCellClick(cell.i) : undefined}
              onMouseEnter={isEditable ? () => onCellEnter(cell.i) : undefined}
            />
          ))}
        </ResponsiveGridLayout>
      </div>
    </div>
  );
};

export default Grid;