import React, { useState, useEffect } from 'react';
import Navbar from '../components/common/navbar'
import BrowseCardEval from '../components/ui/browse-card-eval';
import BrowseCard from '../components/ui/browse-card';
import SearchBar from '../components/ui/search-bar';
import SearchResults from '../components/ui/search-results';
import { searchPuzzles } from '../services/api';
import Divider from '../components/ui/divider';
import '../components/ui/ui.css'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const HomePage = ({ onCreatePuzzle, onPlayPuzzle, onAbout }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchCategory, setSearchCategory] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [userPuzzles, setUserPuzzles] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchLatestPuzzles();
  }, []);

  const fetchLatestPuzzles = async () => {
    try {
      const response = await fetch(`${API_URL}/puzzles/latest`);
      if (!response.ok) {
        throw new Error('Failed to fetch latest puzzles');
      }
      const puzzles = await response.json();
      setUserPuzzles(puzzles);
    } catch (error) {
      console.error('Error fetching latest puzzles:', error);
    }
  };

  const handleRefreshPuzzles = async () => {
    try {
      const response = await fetch(`${API_URL}/puzzles/refresh`);
      if (!response.ok) {
        throw new Error('Failed to refresh puzzles');
      }
      const puzzles = await response.json();
      setUserPuzzles(puzzles);
    } catch (error) {
      console.error('Error refreshing puzzles:', error);
    }
  };

  const handleSearch = async (query, category) => {
    console.log('Search triggered:', query, category);
    setSearchQuery(query);
    setSearchCategory(category);
    setIsSearching(query !== '' || category !== '');
    setPage(1);
    setHasMore(true);

    if (query !== '' || category !== '') {
      try {
        const results = await searchPuzzles(query, category, 1);
        console.log('Search results:', results);
        setSearchResults(results.puzzles);
        setHasMore(results.currentPage < results.totalPages);
      } catch (error) {
        console.error('Error searching puzzles:', error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const loadMoreResults = async () => {
    if (!hasMore) return;

    try {
      const nextPage = page + 1;
      const results = await searchPuzzles(searchQuery, searchCategory, nextPage);
      setSearchResults(prevResults => [...prevResults, ...results.puzzles]);
      setPage(nextPage);
      setHasMore(results.currentPage < results.totalPages);
    } catch (error) {
      console.error('Error loading more results:', error);
    }
  };

  const handleToCreate = () => {
    onCreatePuzzle();
  };

  const handleToPlay = () => {
    onPlayPuzzle();
  }

  const handleToAbout = () => {
    onAbout();
  }

  const handlePlayArcAgiPuzzle = (puzzle) => {
    onPlayPuzzle(puzzle);
  };

  return (
    <div className="app">
      <Navbar 
        onPlayPuzzle={handleToPlay}
        onCreatePuzzle={handleToCreate}
        onAbout={handleToAbout}
        showLoginRegister={false}
      />
      <div className='container'>
        <SearchBar onSearch={handleSearch} />
        <Divider />
        {isSearching ? (
          <SearchResults 
            puzzles={searchResults}
            onPlayPuzzle={onPlayPuzzle}
            onLoadMore={loadMoreResults}
            hasMore={hasMore}
          />
        ) : (
          <>
            <div className='eval-puzzle-container'>
              <h2 className='eval-puzzle-title'>Play from the ARC-AGI Public Evaluation Dataset</h2>
              <BrowseCardEval onPlayPuzzle={handlePlayArcAgiPuzzle} />
            </div>
            <Divider />
            <div className='browse-puzzle-container'>
              <h2 className='browse-puzzle-title'>Browse through User-Created Puzzles</h2>
              <button className="refresh-button" onClick={handleRefreshPuzzles}>Refresh Puzzles</button>
              {userPuzzles.map((puzzle) => (
                <BrowseCard key={puzzle.puzzleId} puzzle={puzzle} onPlayPuzzle={onPlayPuzzle} />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HomePage;