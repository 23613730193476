import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

export const searchPuzzles = async (query, category, page = 1, limit = 10) => {
  try {
    const response = await axios.get(`${API_URL}/puzzles/search`, {
      params: {
        query,
        category,
        page,
        limit
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error searching puzzles:', error);
    throw error;
  }
};
