import React, { useState, useEffect, useCallback } from 'react';
import Navbar from '../components/common/navbar'
import Card from '../components/ui/card';
import MetadataCard from '../components/ui/metadata-card';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const CreationPage = ({ onBack, initialPuzzleData, onUploadSuccess }) => {
  const [examples, setExamples] = useState([
    { id: 1, title: 'Example 1', data: { input: [], output: [] } },
    { id: 2, title: 'Example 2', data: { input: [], output: [] } },
    { id: 3, title: 'Example 3', data: { input: [], output: [] } },
  ]);
  const [test, setTest] = useState({ input: [], output: [] });
  const [metadata, setMetadata] = useState({
    title: '',
    description: '',
    category: '',
    tags: []
  });
  
  useEffect(() => {
    if (initialPuzzleData) {
      setExamples(initialPuzzleData.train.map((example, index) => ({
        id: index + 1,
        title: `Example ${index + 1}`,
        data: example
      })));
      setTest(initialPuzzleData.test[0]);
      if (initialPuzzleData.metadata) {
        setMetadata(initialPuzzleData.metadata);
      }
    }
  }, [initialPuzzleData]);

  const handleUpdate = useCallback((id, data) => {
    if (id === 'test') {
      setTest(data);
    } else {
      setExamples(prev => prev.map(example => 
        example.id === id ? { ...example, data } : example
      ));
    }
  }, []);

  const handleAddExample = () => {
    if (examples.length < 5) {
      const newId = Math.max(...examples.map(e => e.id)) + 1;
      setExamples([...examples, { id: newId, title: `Example ${newId}`, data: { input: [], output: [] } }]);
    }
  };
  
  const handleRemoveExample = () => {
    if (examples.length > 1) {
      setExamples(examples.slice(0, -1));
    }
  };

  const handleExport = () => {
    const exportData = {
      title: metadata.title,
      description: metadata.description,
      category: metadata.category,
      train: examples.map(example => example.data),
      test: [test],
      tags: metadata.tags.length > 0 ? metadata.tags : undefined,
      creatorUsername: metadata.username,
    };
    
    const jsonString = JSON.stringify(exportData, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const href = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = href;
    link.download = 'exported_puzzle.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleMetadataChange = (newMetadata) => {
    setMetadata(newMetadata);
  };

  const handleUpload = async () => {
    const puzzleData = {
      title: metadata.title,
      description: metadata.description,
      category: metadata.category,
      train: examples.map(example => example.data),
      test: [test],
      tags: metadata.tags.length > 0 ? metadata.tags : undefined,
      creatorUsername: metadata.username,
    };

    try {
      const response = await fetch(`${API_URL}/puzzles/upload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(puzzleData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      onUploadSuccess(result.puzzleId);
    } catch (error) {
      console.error('Error uploading puzzle:', error);
      alert('Error uploading puzzle. Please try again.'); // Show error message
    }
  };

  // Create puzzle view
  return (
    <div className="app">
      <Navbar 
        onExport={handleExport}
        onAddExample={handleAddExample}
        onRemoveExample={handleRemoveExample}
        canAddExample={examples.length < 5}
        canRemoveExample={examples.length > 1}
        onBack={onBack}
        onUploadToDatabase={handleUpload}  // Changed from onUpload to onUploadToDatabase
        showLoginRegister={false}
      />
      <div className='container'>
        <MetadataCard onMetadataChange={handleMetadataChange} />
        {examples.map(example => (
          <Card key={example.id} title={example.title} onUpdate={(data) => handleUpdate(example.id, data)} />
        ))}
        <Card title='Test' onUpdate={(data) => handleUpdate('test', data)} isTestCard={true}/>
      </div>
    </div>
  );
};
  
export default CreationPage;