import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-800 py-4" style={{ color: 'white' }}>
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <p className="text-sm !text-white debug-text-color">
            © {currentYear} ARC Playground. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;